<template>
    <div>
        <el-dialog  :visible.sync="showPopup" width="90%" class="my-dialog-class"  :close-on-click-modal="false" >
            
            <span slot="title">
                <el-row>
                    <el-col :md="2" :xs="12">
                        <el-button type="primary" size="mini" @click="show_dialog_add()">Adauga task</el-button>
                    </el-col>
                    <el-col :md="4"  :xs="12">
                        <el-checkbox v-model="Filters.doarActive" true-label="1" false-label="0" @change="refresh_info()">Doar 'in asteptare'</el-checkbox>
                    </el-col>
                </el-row>
            </span>
            <div>
                

                <el-table :data="taskuri" v-loading="loadingVisible" :height="500" :border="true" :row-class-name="tableRowClassName">
                    
                    <el-table-column label="Info" v-if="is_mobile()" width="140">
                        <template slot-scope="scope">
                            <div  style="font-size:10px">
                                <el-tag  v-if="scope.row.Status=='normal'" size="mini">In asteptare</el-tag>
                                <el-tag  v-if="scope.row.Status=='amanat'" type="warning" size="mini">Amânat</el-tag>
                                <el-tag  v-if="scope.row.Status=='renuntare'" type="danger" size="mini">Anulat</el-tag>
                                <el-tag  v-if="scope.row.Status=='finalizat'" type="success" size="mini">Finalizat</el-tag>

                                <br/>
                                <el-tag v-if="scope.row.Status!='normal'" size="mini">
                                    {{scope.row.DataOperare}}
                                </el-tag>
                                <hr/>
                                Creat de <el-tag size='mini'>{{scope.row.User}}</el-tag> <br/>
                                <el-tag size='mini'>{{scope.row.DataCreare}}</el-tag>  <br/>
                                <hr/>
                                Pentru <el-tag size='mini' type="success">{{scope.row.Asignat}}</el-tag> <br/>
                                <el-tag size='mini' type="success" >{{scope.row.TermenFinalizare}}</el-tag> 
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Mesaj" v-if="is_mobile()">
                        <template slot-scope="scope">
                            <el-popover placement="bottom" width="150" trigger="click" v-if="scope.row.Status != 'amanat'">
                                <div>
                                    <el-tooltip content="Reactivare" v-if="scope.row.Status == 'finalizat' || scope.row.Status == 'renuntare'">
                                        <el-button type="danger" size='mini' icon="el-icon-back"  circle @click="reactivare(scope.row.Id)" />
                                    </el-tooltip>

                                    <el-tooltip content="Validare" v-if="scope.row.Status == 'normal'">
                                        <el-button type="success" size='mini' icon="el-icon-check"  circle @click="validare(scope.row.Id)" />
                                    </el-tooltip>

                                    <el-tooltip content="Editare"  v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                        <el-button type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog_edit(scope.row.Id)" />
                                    </el-tooltip>

                                    <el-tooltip content="Amânare" v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                        <el-button type="warning" size='mini' icon="el-icon-timer"  circle @click="show_dialog_amanare(scope.row.Id)" />
                                    </el-tooltip>

                                    <el-tooltip content="Anulare" v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                        <el-button type="danger" size='mini' icon="el-icon-delete"  circle @click="renuntare(scope.row.Id)" />
                                    </el-tooltip>
                                </div>
                                <el-button slot="reference" style="float:right; padding: 10px !important; margin-left: 5px" icon="el-icon-arrow-down"  size='mini'/>
                            </el-popover>
                            <div >
                                {{scope.row.Mesaj}}
                            </div>
                        </template>
                    </el-table-column>


                    <el-table-column prop="DataCreare" min-width="170" label="Data Creare" v-if="!is_mobile()">
                        
                        <template slot-scope="scope">
                            {{scope.row.DataCreare}}
                            <br/>
                            de <el-tag size='mini'>{{scope.row.User}}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column  label="Termen finalizare" min-width="150" v-if="!is_mobile()">
                        <template slot-scope="scope">
                            <el-tag size='mini'>{{scope.row.TermenFinalizare}}</el-tag>
                        </template>
                    </el-table-column> 
                    <el-table-column prop="Asignat" min-width="180" label="Asignat"  v-if="!is_mobile()">
                        <template slot-scope="scope">
                            <el-tag type="success" style="font-weight:bold; color: black">{{scope.row.Asignat}}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column prop="Mesaj"   label="Mesaj" min-width="500"  v-if="!is_mobile()"/>

                    <el-table-column prop="Status" label="Status" min-width="150" v-if="!is_mobile()">
                        <template slot-scope="scope">
                            <el-tag  v-if="scope.row.Status=='normal'" size="mini">In asteptare</el-tag>
                            <el-tag  v-if="scope.row.Status=='amanat'" type="warning" size="mini">Amânat</el-tag>
                            <el-tag  v-if="scope.row.Status=='renuntare'" type="danger" size="mini">Anulat</el-tag>
                            <el-tag  v-if="scope.row.Status=='finalizat'" type="success" size="mini">Finalizat</el-tag>

                            <br/>
                            <el-tag v-if="scope.row.Status!='normal'" size="mini">
                                {{scope.row.DataOperare}}
                            </el-tag>

                        </template>

                    </el-table-column>


                    <el-table-column label="Actiuni" width="170" v-if="!is_mobile()">
                        <template slot-scope="scope">

                            
                                <el-tooltip content="Reactivare" v-if="scope.row.Status == 'finalizat' || scope.row.Status == 'renuntare'">
                                    <el-button type="danger" size='mini' icon="el-icon-back"  circle @click="reactivare(scope.row.Id)" />
                                </el-tooltip>

                                <el-tooltip content="Validare" v-if="scope.row.Status == 'normal'">
                                    <el-button type="success" size='mini' icon="el-icon-check"  circle @click="validare(scope.row.Id)" />
                                </el-tooltip>

                                <el-tooltip content="Editare"  v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                    <el-button type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog_edit(scope.row.Id)" />
                                </el-tooltip>

                                <el-tooltip content="Amânare" v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                    <el-button type="warning" size='mini' icon="el-icon-timer"  circle @click="show_dialog_amanare(scope.row.Id)" />
                                </el-tooltip>

                                <el-tooltip content="Anulare" v-if="scope.row.Status != 'finalizat' && scope.row.Status != 'amanat' && scope.row.Status != 'renuntare'">
                                    <el-button type="danger" size='mini' icon="el-icon-delete"  circle @click="renuntare(scope.row.Id)" />
                                </el-tooltip>
                          
                        </template>
                    </el-table-column>

                </el-table>
            </div>

            
        </el-dialog>
        <TaskAddEdit-dialog ref="dlg-add-edit-task" @save="refresh_info()"></TaskAddEdit-dialog>
    </div>

</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage';
import moment from 'moment';
import TaskAddEdit_dialog from '@/pages/locatii/TaskAddEdit_dialog.vue';

export default {
    name: "Taskuri_dialog",
    extends: BasePage,
    components: {
        'TaskAddEdit-dialog': TaskAddEdit_dialog
    },
    data () {
        return {
            loadingVisible: false,
            baseUrl :'',
            id_locatie: -1,
            showPopup: false,
            Filters: {
                doarActive: '1'
            },
            taskuri: [],
        }
    },
    methods: {
        show_me: async function( id_locatie ) {
            this.id_locatie       = id_locatie;
            this.showPopup        = true;
            this.taskuri          = [];
            this.refresh_info();
        },

        show_dialog_add() {
            this.$refs['dlg-add-edit-task'].show_me("add", this.id_locatie);
        },

        async renuntare(id_task){
            var response = await this.post("locatii/renuntare_task", { id_task: id_task });
            await this.refresh_info();
        },

        async reactivare(id_task){
            var response = await this.post("locatii/reactivare_task", { id_task: id_task });
            await this.refresh_info();
        },

        is_mobile() {
            return window.innerWidth < 1024;
        },

        async show_dialog_amanare(id_task){
            var response = await this.post("locatii/amanare_task", { id_task: id_task });
            await this.refresh_info();
            this.$refs['dlg-add-edit-task'].show_me("edit", -1, response.Task.Id);
        },

        show_dialog_edit(id_task) {
            this.$refs['dlg-add-edit-task'].show_me("edit", -1, id_task);
        },

        tableRowClassName({row, rowIndex}){
            return "rand-"+row.CuloareStatus;
        },

        async validare( id_task ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("locatii/validare_task", { id: id_task } );
                this.refresh_info();
            }
        },

        async get_info() { },

        async refresh_info() {
            this.loadingVisible = true;
            var response        = await this.post("locatii/list_taskuri", { id_locatie: this.id_locatie, Filters: this.Filters } );
            this.taskuri        = response.Taskuri;
            this.loadingVisible = false;
        },

        save: async function() {
            
        }
    },
    mounted: function() {
        this.baseUrl = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>
.full-width {
    width: 100%;
}

</style>